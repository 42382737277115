
/* .sidebar .nav-dropdown-toggle {
padding-left: 2px;
} */
.errorMessage {
  color: red;
  font-size: 1em;
  display: relative;
}
.sidebar .nav-link.active{
  background: #20a8d8
}
.iconBtn {
padding-left: 1px;
padding-right: 1px;
padding-bottom: 1px;
padding-top: 1px;
}
.float-left {
  float: left !important; 
}

.float-right {
  float: right !important; 
}

.float-none {
  float: none !important; 
}
.form-group {
  margin-bottom: 1rem; 
}