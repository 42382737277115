
.CenterMap {
  float: none;
	border-style: solid;
	border-color:dimgrey;
	padding: 0px;
	margin: 0px;
}

.leftHand {
	border-style: solid;
	border-color:dimgrey;
	padding: 0px;
	margin: 0px;
}
.rightHand {
float:left;
overflow-y: scroll ;
height: 400px;
width: 100%;
padding-left: 5px;
}
.myPadding {
    padding-right: 0px;
    padding-left: 0px;
}

/* .rightHand::-webkit-scrollbar {
    width: 8px;
}

.rightHand::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 5px;
}

.rightHand::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */

.inputDiv{
	padding-bottom: 5px;
}
/* .hideimage{
    display: none;
} */

/* .zeft{
height: 500px;
} */
/*
.zest {
	width: 30%;
	height: 400px;
}
*/
/*
.marker-menu {
	background: #eee;
	width: 50px;
	height: 70%;
	border: 1px solid #fff;
	float: left;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.draggable-marker {
    width: 20px;
    margin: 12px;
    cursor: pointer;
    z-index: 2;
}
*/


/*
.sidebarr {
    background-color: khaki;
    width: 150px;
	height: 100%;
    position: absolute;
    top: 50;
    left: 50;
    bottom: 50;
}

.sidebarr h1 {
    color: white;
}

.portfolio {
    background-color: snow;
    position: absolute;
    top: 100;
    left: 150px;
    right: 200;
    bottom: 0;

    -moz-transition: left 0.5s ease;
    transition: left 0.5s ease;
}

input[type=checkbox] {
   display: none;
}

input:checked ~ .portfolio {
    left: 0;
}

input:checked ~ label {
    left: 0;
}

label {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 150px;
    background-color: blue;

    -moz-transition: left 0.5s ease;
    transition: left 0.5s ease;
}
*/
